export default theme => ({
  snackbar: {
    marginBottom: 20,
    borderRadius: 2,
    padding: '3px 9px 3px 18px',
    textAlign: 'left',
    lineHeight: theme.typography.pxToRem(16),
    boxShadow: 'none',
  },

  alert: {
    backgroundColor: theme.lighten(theme.palette.statuses.rejected, 0.8),
    border: `1px solid ${theme.palette.statuses.rejected}`,
    color: theme.palette.statuses.rejected,
  },

  success: {
    backgroundColor: theme.lighten(theme.palette.statuses.success, 0.45),
    border: `1px solid ${theme.palette.statuses.success}`,
    color: theme.palette.statuses.success,
  },

  info: {
    backgroundColor: theme.lighten(theme.palette.blue40, 0.75),
    border: `0.5px solid ${theme.palette.statuses.info}`,
    color: theme.palette.statuses.info,
  },

  notice: {
    backgroundColor: theme.lighten(theme.palette.blue40, 0.75),
    border: `0.5px solid ${theme.palette.statuses.info}`,
    color: theme.palette.statuses.info,
  },
});
