import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { head, last } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';

class Flash extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    flash: PropTypes.shape().isRequired,
    onClose: PropTypes.func,
    toast: PropTypes.bool,
  };

  static defaultProps = {
    onClose: () => {},
  };

  messageTypes = ['alert', 'success', 'info', 'notice'];

  state = {
    flashType: head(this.props.flash),
    message: last(this.props.flash),
    showFlash: true,
  };

  handleClose = () => {
    this.setState({ showFlash: false });
    this.props.onClose();
  };

  render() {
    const { showFlash, message, flashType } = this.state;
    const { classes, toast } = this.props;
    const flashClass = this.messageTypes.includes(flashType) ? classes[flashType] : classes.alert;

    return (
      showFlash &&
      message.length > 0 && (
        <SnackbarContent
          data-node="flash_alert"
          className={cn(classes.snackbar, flashClass)}
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{message}</span>}
          action={
            toast
              ? []
              : [
                  <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                    <Close className={classes.alertIcon} />
                  </IconButton>,
                ]
          }
        />
      )
    );
  }
}

export default withStyles(styles)(Flash);
